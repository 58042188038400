import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Link, Text } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Note variant="warning" mdxType="Note">
  <Text sx={{
        display: 'block',
        fontWeight: 'bold',
        mb: 2
      }} mdxType="Text">Guidelines for this component are in progress</Text>
  <Text mdxType="Text">Interested in contributing? Review the guidelines for <Link as={GatsbyLink} to="/guides/contribute/documentation#documenting-components" mdxType="Link">writing component documentation</Link> and open a pull request in our <Link as={GatsbyLink} to="https://github.com/primer/design" mdxType="Link">documentation repository.</Link></Text>
    </Note>
    <br />
    <img src="https://github.com/primer/design/assets/586552/f4f48662-4269-4067-b1fb-b56e24ba8e4d" role="presentation" width="960" alt="Subnav component" />
    <h2>{`Usage`}</h2>
    <p>{`Use the subnav component for navigation on a dashboard-type interface with another set of navigation components above it. This helps distinguish navigation hierarchy.`}</p>
    <p>{`Make sure to properly label your subnav with an aria-label to provide context about the type of navigation contained in subnav.`}</p>
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="SubNav" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      